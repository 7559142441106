import helix from './helix'
import Cookies from 'js-cookie';
//import Banners from './banners';
import HelixForm from './HelixForm';

//const banners = new Banners();

window.addEventListener('DOMContentLoaded', (e) => {
    // Menu button
    const menuButton = document.querySelector('button.menu');
    if (menuButton) {
        menuButton.addEventListener('click', (e) => {
            document.body.classList.toggle('nav-open')
        });
    }
    

    // Inquiry form initialization
    document.querySelectorAll('.helixform').forEach((form) => {
        let helixForm = new HelixForm();
        helixForm.validation = {
            "first_name": [
                {type: "required"}
            ],
            "last_name": [
                {type: "required"}
            ],
            "email": [
                {type: "required"},
                {type: "email"},
            ],
            "phone": [
                {type: "required"},
                {type: "phone"}
            ],
            "address": [
                {type: "required"}
            ],
            "city": [
                {type: "required"}
            ],
            "state": [
                {type: "required"}
            ],
            "postal_code": [
                {type: "required"},
                {type: "postal_code"}
            ],
            "education_level_code": [
                {type: "required"}
            ],
            "grad_year": [
                {type: "required"}
            ],
        }
        helixForm.initialize(form);
    })


});